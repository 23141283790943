import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "reactstrap";
import Img from "gatsby-image";
import { Helmet } from "react-helmet";
import v4 from "uuid/v4";

import Blocks from "../components/Blocks";
import Breadcrumb from "../components/Breadcrumb";
import Level0 from "../components/Menu/Level0";
import Level12 from "../components/Menu/Level12";

import { htmlDecode } from "../utils/functions";

const MenuPageTemplate = ({ pageContext, location }) => {
  const { currentNode, level0, level1, level2 } = pageContext;
  const {
    wordpress_id,
    wordpress_parent,
    featured_media,
    title,
    metadesc,
    level,
    hierarchy,
    blocks,
    content_page,
  } = currentNode;

  const previousPage = hierarchy.find((el) => el.post_id === wordpress_parent);

  const img =
    featured_media &&
    featured_media.wordpress_id &&
    pageContext.images.find(
      (e) => e.wordpress_id === featured_media.wordpress_id
    );
  const bannerImage =
    img &&
    img.localFile &&
    img.localFile.childImageSharp &&
    img.localFile.childImageSharp.sizes;

  const previousPathName =
      location && location.state && location.state.prevPathName,
    currentPathName = currentNode && currentNode.full_slug,
    previousLevel = location && location.state && location.state.prevLevel;

  let animation = "";
  if (previousPathName && currentPathName) {
    if (level === 2 && currentPathName.includes(previousPathName)) {
      animation = "menu-in";
    }
    if (level === 1 && previousLevel === 2) {
      animation = "menu-out";
    }
  }

  return (
    <div
      className={`page-template menu-page-template h-100 d-flex flex-column`}
    >
      <Helmet>
        <title>{htmlDecode(title)}</title>
        {metadesc && <meta name="description" content={metadesc} />}
      </Helmet>
      <div className="offline-fallback-fix-no-idea-why"></div>
      <div className="menu-page-banner">
        <div className="banner-content">
          <div className="container">
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </div>
        {bannerImage && bannerImage.base64 !== "undefined" && (
          <Img className="d-none d-lg-block" sizes={bannerImage} />
        )}
      </div>
      <div
        className={`menu-page flex-grow-1 d-flex flex-column ${"level-" +
          level} ${animation}`}
      >
        <Breadcrumb
          title={title}
          id={wordpress_id}
          parent={wordpress_parent}
          hierarchy={hierarchy}
        />
        <Container className="flex-grow-1 d-flex flex-column">
          <Row className="flex-grow-1">
            <Col tag="aside" sm="12" lg="4">
              <div className="anchors-sidebar h-100">
                <div className="bg-light h-100">
                  <div className="sticky-top py-5">
                    {level0 && (
                      <Level0
                        items={Object.keys(level0).map((k) => level0[k])}
                        currentNodeId={currentNode.wordpress_id}
                        currentPathName={currentPathName}
                        currentLevel={level}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!!(level === 2 && previousPage && previousPage.post_link) && (
                <Link
                  className="level1-overlay"
                  to={previousPage.post_link}
                  state={{
                    prevPathName: currentPathName,
                    prevLevel: level,
                    menuNavigation: true,
                  }}
                />
              )}
            </Col>
            <Col
              tag="article"
              sm="12"
              lg="8"
              className={level === 2 ? " deployed" : ""}
            >
              {level === 0 && (
                <div className="blocks-container pt-5 pl-5">
                  {blocks.map(
                    (f) => f.blockName && <Blocks key={v4()} {...f} />
                  )}
                </div>
              )}
              {level1 && (
                <div className="submenu-container level-1">
                  <div className="py-5 pl-5 pr-5 menu-level-1">
                    <Level12
                      items={Object.keys(level1).map((k) => level1[k])}
                      currentNodeId={currentNode.wordpress_id}
                      currentPathName={currentPathName}
                      currentLevel={level}
                    />
                    {level === 1 &&
                      !content_page &&
                      blocks &&
                      blocks.map(
                        (f) => f.blockName && <Blocks key={v4()} {...f} />
                      )}
                  </div>

                  {level2 && (
                    <div className="submenu-container level-2">
                      <div className="py-5 pl-5 pr-5 menu-level-2">
                        <Level12
                          items={Object.keys(level2).map((k) => level2[k])}
                          currentNodeId={currentNode.wordpress_id}
                          currentPathName={currentPathName}
                          currentLevel={level}
                        />
                        {level === 2 &&
                          !content_page &&
                          blocks &&
                          blocks.map(
                            (f) => f.blockName && <Blocks key={v4()} {...f} />
                          )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MenuPageTemplate;
