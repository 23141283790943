import React from 'react'
import { Link } from 'gatsby'

const isPartiallyActive = ({isPartiallyCurrent}) => isPartiallyCurrent && { className: "list-menu-link active" }

const Level0 = ({items, currentNodeId, currentPathName, currentLevel}) =>
	<ul className="list-menu list-unstyled">
		{items.map(({full_slug, title, content_page, hide_menu, wordpress_parent}) => (!hide_menu  || currentNodeId !== wordpress_parent) &&
			<li key={full_slug} className="list-menu-item pl-3 pr-4">
				<Link 
					className={`list-menu-link ${(content_page ? 'content-page' : '')}`} 
					to={full_slug} 
					getProps={isPartiallyActive} 
					dangerouslySetInnerHTML={{ __html: title}} 
					state={{
						prevPathName: currentPathName, 
						prevLevel: currentLevel,
						menuNavigation: !content_page 
					}}
					>
				</Link>
			</li>
		)}
	</ul>

export default Level0
