import React from 'react'
import { Link } from 'gatsby'

const isPartiallyActive = ({isPartiallyCurrent}) => isPartiallyCurrent && { className: "list-menu-link active" }
const description = blocks => {
	const desc = blocks.filter(({blockName}) => blockName === "cgb/block-page-description")[0]
	return desc && desc.attrs.description
}


const Level1_2 = ({items, currentNodeId, currentPathName, currentLevel}) => 
		<ul className="list-menu list-unstyled">
		{items.map(({hide_menu, full_slug, title, content_page, blocks, wordpress_parent}) => (!hide_menu  || currentNodeId !== wordpress_parent) &&
			<li key={full_slug} className="list-menu-item">
				<Link 
					className={`list-menu-link ${(content_page ? 'content-page' : '')}`} 
					to={full_slug} 
					getProps={isPartiallyActive} 
					state={{
						prevPathName: currentPathName, 
						prevLevel: currentLevel, 
						menuNavigation: !content_page 
						}}
					>
					<strong dangerouslySetInnerHTML={{ __html: title}} /><br />
					<small className="description">{description(blocks)}</small>
				</Link>
			</li>
		)}
	</ul>

	

export default Level1_2
